import { useNavigate } from 'react-router-dom';

import { Table } from 'ui/tables/table/Table';
import { useProfilesListColumns } from '../hooks/useProfilesListColumns/useProfilesListColumns';
import { Profile } from 'api/actions/profiles/profilesActions.types';
import { useProfilesGet } from 'api/actions/profiles/profilesActions';
import { AppRoute } from 'routing/AppRoute.enum';
import { StepsRoutesNames, Steps } from 'views/profileEditor/ProfileEditor.types';

import { UsersListProps } from './ProfilesList.types';
import * as S from './ProfilesList.styles';
import { filtersOperators, getProfilesListParams, keysMapper, virtualSortingMapper } from './ProfilesList.utils';

export const ProfilesList = ({
  onRowSelect,
  globalFilter,
  queryParamsDecoded,
  isDwhSelected = true,
}: UsersListProps) => {
  const columns = useProfilesListColumns();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useProfilesGet({
    params: getProfilesListParams(queryParamsDecoded, { isDwhSelected }),
  });

  const navigateToUserDetails = (profileId: string) => {
    return navigate(`${AppRoute.ProfileEditor}/${StepsRoutesNames[Steps.BasicData]}/${profileId}`);
  };

  return (
    <Table<Profile>
      onRowSelect={onRowSelect}
      enableRowsSelection={true}
      keysMapper={keysMapper}
      filtersOperators={filtersOperators}
      sx={{ tableHead: S.tableHead }}
      columns={columns}
      data={data?.result}
      total={data?.total}
      isLoading={isLoading}
      isError={isError}
      globalFilter={globalFilter}
      virtualSortingMap={virtualSortingMapper}
      onRowClick={(row) => navigateToUserDetails(row.id)}
      defaultSort={[{ id: 'employee', desc: false }]}
    />
  );
};
