import { FilterOperator, VirtualSortingMapper } from 'utils';

export const filtersOperators: { [key: string]: FilterOperator } = {
  employee: FilterOperator.includeOrBracket,
  position: FilterOperator.includeOr,
  email: FilterOperator.includeOrBracketEnd,
};

export const keysMapper: { [key: string]: string } = {
  employee: "profile.lastName || ' ' || profile.firstName ",
  position: 'profilePosition.visibleName',
  email: 'profile.email',
  surveyUpdatedAt: 'profile.surveyUpdatedAt',
  updateConfirmedAt: 'profile.updateConfirmedAt',
};

export const virtualSortingMapper: VirtualSortingMapper = {
  employee: ['profile.lastName', 'profile.firstName'],
  updateConfirmedAt: ['profile.updateConfirmedAt'],
  surveyUpdatedAt: ['profile.surveyUpdatedAt'],
};

export const getProfilesListParams = (
  queryParams: Record<string, string | number>,
  options: { isDwhSelected?: boolean },
) => {
  const isSortParams = Object.keys(queryParams).some((key) => key.startsWith('sort'));

  const defaultSortParams = isSortParams ? {} : { 'sort[profile.lastName]': 'ASC', 'sort[profile.firstName]': 'ASC' };

  return {
    page: '1',
    limit: '10',
    'filter[profile.archived][eq]': false,
    'filter[profile.isDwhMatched][eq]': options.isDwhSelected,
    relations: ['profile.position'],
    ...defaultSortParams,
    ...queryParams,
  };
};
