import { Styles } from 'lux/theme';

export const filterInput: Styles = ({ spacing }) => ({
  '& .MuiInputBase-root': {
    minHeight: 40,
  },
  '& .MuiOutlinedInput-input.MuiInputBase-input': {
    padding: spacing(0.5, 1.25),
  },
  minWidth: 'unset',
});
