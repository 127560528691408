import { FormProvider } from 'react-hook-form';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage } from 'react-intl';

import { Typography, Separator, Button, Modal, Loader } from 'ui';
import { usePrompt } from 'hooks';

import * as Styles from './FormModalWrapper.styles';
import { FormModalWrapperProps } from './FormModalWrapper.types';

export const FormModalWrapper = <T extends Record<string, unknown>>({
  children,
  header,
  isOpen,
  onSubmit,
  onClose,
  isLoading,
  formMethods,
  isDataFetching,
  ...props
}: FormModalWrapperProps<T>) => {
  const { trigger } = formMethods;

  usePrompt({ when: formMethods.formState.isDirty });

  const handleSubmit = async () => {
    const isValid = await trigger();
    if (isValid) {
      onSubmit();
    }
  };

  return (
    <Modal isOpen={isOpen} onModalClose={onClose} {...props}>
      <Typography variant="h5" sx={Styles.header}>
        {header}
      </Typography>
      {isDataFetching && <Loader />}
      {!isDataFetching && (
        <FormProvider {...formMethods}>
          <form onSubmit={onSubmit}>
            {children}
            <Separator />
            <Box sx={Styles.buttonsWrapperStyles}>
              <Button label="form.cancel" onClick={onClose} dataTestId="cancel" variant={'outlined'} />
              <LoadingButton
                data-testid="submit"
                type="submit"
                loading={isLoading}
                variant="contained"
                disabled={isLoading}
                onClick={handleSubmit}
              >
                <FormattedMessage id="form.save" />
              </LoadingButton>
            </Box>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};
